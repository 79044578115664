//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex';
import Networks from '~/views/PaymentsAndCurrenciesNetworks/Networks/Table.vue';
import Tokens from '~/views/PaymentsAndCurrenciesNetworks/Tokens/Table.vue';

export default {
  components: {
    Networks,
    Tokens,
  },

  async mounted() {
    this.setGeneralLoader(true);
    await Promise.allSettled([
      this.loadNetworks(),
      this.loadTokens(),
    ]);
    this.setGeneralLoader(false);
  },

  methods: {
    ...mapActions('common', ['setGeneralLoader']),
    ...mapActions('paymentsAndCurrenciesNetworks', {
      loadNetworks: 'loadData',
    }),
    ...mapActions('paymentsAndCurrenciesNetworkTokens', {
      loadTokens: 'loadData',
    }),
  },
};
